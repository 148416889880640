import { createTheme, ThemeOptions } from '@mui/material/styles'
import { ColorTheme } from '../utils/enums'

const round = (value: number) => {
  return Math.round(value * 1e5) / 1e5
}

export const rem = (value: number): string => {
  return `${round(value / 16)}rem`
}

const retina = (): string => {
  return `@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)`
}

const breakpoint = (maxWidth): string => {
  return `@media (max-width:${maxWidth}px)`
}

export function shadeColor(color, percent) {
  let R: number = parseInt(color.substring(1, 3), 16)
  let G: number = parseInt(color.substring(3, 5), 16)
  let B: number = parseInt(color.substring(5, 7), 16)

  R = parseInt(String((R * (100 + percent)) / 100))
  G = parseInt(String((G * (100 + percent)) / 100))
  B = parseInt(String((B * (100 + percent)) / 100))

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}

interface ThemeColors {
  darkGray: string
  darkGray2: string
  darkGray3: string
  darkGray4: string
  ggbGreen: string
  ggbMediumGreen: string
  ggbDarkGreen: string
  playerGroupBackground: string
  primaryBright: string
  primaryExtraLight?: string
  tableBorderColor: string
  tableLightBg: string
  tableAltLightBg: string
  tableRowBg?: string
  lightGray: string
  lightGray2: string
  lightGray3: string
  mediumGray: string
  mediumGray2: string
  dark: string
  background: string
  primaryExtraDark: string
  divider: string
  textColor: string
  tableTextColor: string
  error: string
  success: string
  border: string
  siteNavigationText: string
  siteNavigationBackground: string
  siteFooterBackground: string
  siteFooterText: string
  appAdBackground: string
  brandGradient: string
  inputColor: string
}

declare module '@mui/material/styles' {
  interface Theme {
    customPalette: ThemeColors
    retina(): string
  }
  interface ThemeOptions {
    customPalette?: ThemeColors
    retina(): string
  }

  interface TypographyStyleOptions {
    breakpoint?(): string
  }

  interface TypographyVariants {
    h2PublicSans: React.CSSProperties
    bodyPublicSans: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h2PublicSans?: React.CSSProperties
    bodyPublicSans?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h2PublicSans: true
    bodyPublicSans: true
  }
}

// Declare module augmentation
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    black: Palette['primary']
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary']
  }
}

// Extend Button props
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true
  }
}

function createMyTheme(mode: string, primaryColor: string, options: ThemeOptions) {
  return createTheme({
    customPalette: {
      darkGray: '#d5d5d5',
      darkGray2: '#d8d8d8',
      darkGray3: '#4a4a4a',
      darkGray4: '#404040',
      ggbGreen: '#1AA85D',
      ggbMediumGreen: '#0B8E50',
      ggbDarkGreen: '#14794F',
      playerGroupBackground: '#eae9e9',
      primaryBright: primaryColor,
      tableBorderColor: '#d9d9d9',
      tableLightBg: mode === ColorTheme.LIGHT ? '#E5E5E5' : '#4a4a4a',
      tableAltLightBg: mode === ColorTheme.LIGHT ? '#F5F5F5' : '#383838',
      tableRowBg: mode === ColorTheme.LIGHT ? '#fff' : '#555',
      primaryExtraDark: shadeColor(primaryColor, -30),
      lightGray: '#F2F2F2',
      lightGray2: '#D6D6D6',
      lightGray3: '#A4A4A4',
      mediumGray: mode === ColorTheme.LIGHT ? '#BBBBBD' : '#c4c4c4',
      mediumGray2: mode === ColorTheme.LIGHT ? '#8A8A8E' : '#c4c4c4',
      dark: mode === ColorTheme.LIGHT ? '#4A4A4A' : '#DEDEDE',
      background: mode === ColorTheme.LIGHT ? '#FFFFFF' : '#2C2C2C',
      border: mode === ColorTheme.LIGHT ? '#D6D6D6' : '#555',
      divider: '#9B9B9B',
      textColor: mode === ColorTheme.LIGHT ? '#555' : '#DEDEDE',
      tableTextColor: mode === ColorTheme.LIGHT ? '#565656' : '#EEE',
      error: '#b71c1c',
      success: '#1aa85d',
      siteNavigationText: mode === ColorTheme.LIGHT ? '#404040' : '#fff',
      siteNavigationBackground: mode === ColorTheme.LIGHT ? '#fff' : '#383838',
      siteFooterBackground: mode === ColorTheme.LIGHT ? '#4A4A4A' : '#383838',
      siteFooterText: '#C4C4C4',
      appAdBackground: mode === ColorTheme.LIGHT ? '#F1FBF1' : '#1A1A1A',
      brandGradient: `linear-gradient(to right, ${primaryColor} 0%,${shadeColor(primaryColor, -40)} 100%)`,
      inputColor: mode === ColorTheme.LIGHT ? '#373D40' : '#fff',
    },
    retina,
    ...options,
  })
}

const materialUITheme = (mode, primaryColor) => {
  return createMyTheme(mode, primaryColor, {
    typography: {
      h1: {
        fontSize: rem(68),
        lineHeight: 1.375,
        fontWeight: 800,
        fontStyle: 'italic',
        fontFamily: ['Exo', 'sans-serif'].join(','),
        color: mode === ColorTheme.LIGHT ? '#555555' : '#DEDEDE',
        [breakpoint(960)]: {
          fontSize: rem(48),
        },
        [breakpoint(600)]: {
          fontSize: rem(28),
        },
      },
      h2: {
        fontSize: rem(22),
        lineHeight: 1.375,
        fontWeight: 800,
        fontStyle: 'italic',
        color: primaryColor,
        fontFamily: ['Exo', 'sans-serif'].join(','),
        [breakpoint(600)]: {
          fontSize: rem(18),
        },
        [breakpoint(960)]: {
          fontSize: rem(20),
        },
      },
      h2PublicSans: {
        fontFamily: ['"Public sans"', 'sans-serif'].join(','),
        fontSize: rem(24),
        fontWeight: 900,
        lineHeight: 1.5,
        letterSpacing: '-0.196px',
        color: mode === ColorTheme.LIGHT ? '#004729' : '#FFF',
        [breakpoint(960)]: {
          fontSize: rem(18),
          letterSpacing: '-0.133px',
        },
      },
      h3: {
        fontSize: rem(22),
        lineHeight: 1.375,
        fontWeight: 200,
        fontFamily: ['Exo', 'sans-serif'].join(','),
        color: mode === ColorTheme.LIGHT ? '#555555' : '#DEDEDE',
        [breakpoint(600)]: {
          fontSize: rem(16),
        },
        [breakpoint(960)]: {
          fontSize: rem(18),
        },
      },
      h4: {
        fontSize: rem(20),
        color: primaryColor,
        lineHeight: 1.375,
        fontWeight: 800,
        fontStyle: 'italic',
        fontFamily: ['Exo', 'sans-serif'].join(','),
        [breakpoint(600)]: {
          fontSize: rem(15),
        },
        [breakpoint(960)]: {
          fontSize: rem(17),
        },
      },
      h5: {
        fontSize: rem(16),
        color: primaryColor,
        lineHeight: 1.375,
        fontWeight: 800,
        fontStyle: 'italic',
        fontFamily: ['Exo', 'sans-serif'].join(','),
        [breakpoint(600)]: {
          fontSize: rem(14),
        },
        [breakpoint(960)]: {
          fontSize: rem(15),
        },
      },
      h6: {},
      body1: {
        fontFamily: ["'Roboto'", 'Helvetica', 'Arial', 'sans-serif'].join(','),
        fontSize: rem(14),
        color: mode === ColorTheme.LIGHT ? '#555555' : '#DEDEDE',
        lineHeight: 1.5,
        [breakpoint(600)]: {
          fontSize: rem(13),
        },
      },
      body2: {
        fontFamily: ["'Roboto'", 'Helvetica', 'Arial', 'sans-serif'].join(','),
        fontSize: rem(15),
        color: mode === ColorTheme.LIGHT ? '#555555' : '#DEDEDE',
        [breakpoint(600)]: {
          fontSize: rem(13),
        },
        [breakpoint(960)]: {
          fontSize: rem(14),
        },
      },
      bodyPublicSans: {
        fontFamily: ['"Public sans"', 'sans-serif'].join(','),
        fontSize: rem(18),
        fontWeight: 400,
        color: mode === ColorTheme.LIGHT ? '#032615' : '#BBBBBD',
        lineHeight: 1.4,
        letterSpacing: '-0.196px',
        [breakpoint(960)]: {
          fontSize: rem(14),
          letterSpacing: '-0.133px',
        },
      },
      subtitle1: {
        fontSize: rem(24),
        lineHeight: 1.375,
        fontWeight: 800,
        fontStyle: 'italic',
        color: mode === ColorTheme.LIGHT ? '#555555' : '#DEDEDE',
        fontFamily: ['Exo', 'sans-serif'].join(','),
        [breakpoint(600)]: {
          fontSize: rem(20),
        },
        [breakpoint(960)]: {
          fontSize: rem(22),
        },
      },
      subtitle2: {
        fontSize: rem(14),
        lineHeight: 1.375,
        fontWeight: 500,
        color: '#FFFFFF',
        fontFamily: ['Exo', 'sans-serif'].join(','),
      },
    },
    palette: {
      mode: mode.toLowerCase(),
      primary: {
        main: primaryColor,
        dark: shadeColor(primaryColor, -40),
        contrastText: '#fff',
      },
      secondary: {
        main: '#F5F5F9',
        dark: shadeColor('#F5F5F9', -10),
        contrastText: '#098F50',
      },
      black: {
        main: '#000',
        dark: '#333',
        contrastText: '#fff',
      },
      background: {
        default: '#FFFFFF',
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h2PublicSans: 'h2',
            bodyPublicSans: 'p',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 8,
            maxWidth: 757,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: mode === ColorTheme.LIGHT ? '#F2F2F2' : '#5C5C5C',
            marginBottom: 30,
            color: mode === ColorTheme.LIGHT ? '#555555' : '#DEDEDE',
            fontFamily: ['Exo', 'sans-serif'].join(','),
            fontStyle: 'italic',
            fontSize: rem(18),
            fontWeight: 700,
            padding: '26px 50px',

            [breakpoint(960)]: {
              marginBottom: 20,
              padding: '26px 16px',
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: 50,

            [breakpoint(960)]: {
              padding: 20,
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            '&.linkIcon': {
              cursor: 'pointer',
              '&:hover, &:active': {
                opacity: 0.5,
              },
            },
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            '&.MuiTableSortLabel-root.MuiTableSortLabel-active': {
              color: 'inherit',
            },
            '&.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
              color: 'inherit !important',
            },
            '&:hover': {
              color: 'inherit',
            },
          },
        },
      },
    },
    retina,
  })
}

export default materialUITheme
