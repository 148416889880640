import { TOURNAMENT_LOAD_STARTLISTS } from './constants'

/*
 * TOURNAMENT LOAD STARTLISTS
 */
export interface LoadTournamentStartlistsAction extends Action {
  type: 'TOURNAMENT_LOAD_STARTLISTS'
  id: number
}

export interface LoadTournamentStartlistsSuccessAction extends Action {
  type: 'TOURNAMENT_LOAD_STARTLISTS_SUCCESS'
  startlists: Group[][]
  loading: boolean
}
export interface LoadTournamentStartlistsFailureAction extends Action {
  type: 'TOURNAMENT_LOAD_STARTLISTS_FAILURE'
  error: ErrorPayload
}
export const loadTournamentStartlists = (id: number) => ({
  type: TOURNAMENT_LOAD_STARTLISTS,
  id,
})
