/* eslint-disable max-len */

export const PenIcon = ({ style = {}, width = '100%', className = '', height = '100%', viewBox = '0 0 20 20' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    style={style}
    fill="none"
  >
    <path
      d="M9.40009 16.1612L9.4001 16.1612L16.796 8.76532C15.7894 8.34637 14.5972 7.6582 13.4697 6.53068C12.342 5.40298 11.6537 4.21058 11.2348 3.2039L3.83882 10.5999L3.83879 10.5999C3.26166 11.1771 2.97307 11.4657 2.7249 11.7838C2.43213 12.1592 2.18114 12.5653 1.97634 12.995C1.80273 13.3593 1.67368 13.7465 1.41556 14.5208L0.0544484 18.6042C-0.0725722 18.9852 0.0266047 19.4053 0.310631 19.6894C0.594658 19.9734 1.01478 20.0726 1.39584 19.9456L5.47918 18.5844C6.25351 18.3263 6.6407 18.1973 7.00498 18.0237C7.43469 17.8189 7.84082 17.5679 8.21616 17.2751C8.53435 17.0269 8.82296 16.7383 9.40009 16.1612Z"
      fill="#098F50"
    />
    <path
      d="M18.8482 6.71306C20.3839 5.17735 20.3839 2.68748 18.8482 1.15178C17.3125 -0.383926 14.8226 -0.383926 13.2869 1.15178L12.3999 2.03882C12.4121 2.0755 12.4246 2.11268 12.4377 2.15035C12.7628 3.0875 13.3763 4.31601 14.5303 5.47002C15.6843 6.62403 16.9128 7.23749 17.85 7.56262C17.8875 7.57563 17.9245 7.58817 17.961 7.60026L18.8482 6.71306Z"
      fill="#098F50"
    />
  </svg>
)
