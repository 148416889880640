import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { setNetsPaymentAsFailed, verifyNetsPayment } from '@app/store/payments/actions'
import { FormattedMessage } from 'react-intl'
import { PaymentResponseCode, getPaymentReturnUrl } from '@app/utils/paymentUtils'
import { usePaymentState } from '@app/hooks'
import { useLanguage } from '@app/hooks/useLanguage'
import SignupSuccessModal from './SignupSuccessModal'
import Popup from './Popup'

export const PaymentProcessDialog = () => {
  const dispatch = useDispatch()
  const payments = useSelector((store: StoreState) => store.paymentReducer)
  const tournamentSite = useSelector((store: StoreState) => store.tournamentReducer.tournamentSite)
  const tournamentId = tournamentSite?.tournament?.id
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const { transactionId, responseCode } = usePaymentState()
  const { languageCode } = useLanguage()

  useEffect(() => {
    if (transactionId && responseCode === PaymentResponseCode.OK && tournamentId > 0) {
      dispatch(verifyNetsPayment({ transactionId, lang: languageCode }))
      setDialogOpen(true)
    }
  }, [transactionId, responseCode, tournamentId])

  useEffect(() => {
    if (transactionId && responseCode !== PaymentResponseCode.OK) {
      dispatch(setNetsPaymentAsFailed())
      setDialogOpen(true)
    }
  }, [transactionId, responseCode])

  const handleCloseOnClick = () => {
    // Remove payment-return and params from url
    const url = getPaymentReturnUrl(tournamentSite.tournament.id)
    window.history.pushState('', '', url)

    setDialogOpen(false)
    sessionStorage.clear()
  }

  const renderTitle = () => {
    if (payments.loading) {
      return <FormattedMessage id="payments.verifyingPaymentTitle" />
    } else {
      return <FormattedMessage id="strings.error" />
    }
  }

  const renderText = () => {
    if (payments.loading) {
      return <CircularProgress size={64} thickness={2} />
    } else {
      return <FormattedMessage id="payments.paymentVerificationFailed" />
    }
  }

  if (!tournamentSite) {
    return null
  }

  // Success
  if (!payments.error && !payments.loading) {
    return <SignupSuccessModal open={isDialogOpen} />
  }

  // Loading/error
  return (
    <Popup
      open={isDialogOpen}
      type={payments.loading ? 'neutralGreen' : 'warning'}
      wide={true}
      title={renderTitle()}
      text={renderText()}
      okAction={payments.loading ? undefined : handleCloseOnClick}
      okText={<FormattedMessage id="buttons.close" />}
    />
  )
}
