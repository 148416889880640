type SimulatorIconProps = {
  width: number
  height: number
}

export const SimulatorIcon: React.FC<SimulatorIconProps> = ({ width = 33, height = 18 }) => (
  <svg width={width} height={height} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="16" rx="7" fill="#004E8F" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      // eslint-disable-next-line max-len
      d="M5.43934 3.92225C5 4.34401 5 5.02283 5 6.38046V6.86045C5 8.21808 5 8.8969 5.43934 9.31866C5.87868 9.74042 6.58579 9.74042 8 9.74042H9.625V10.9209L6.88142 11.7989C6.68494 11.8617 6.57875 12.0656 6.64424 12.2542C6.70974 12.4429 6.92211 12.5448 7.11859 12.4819L10 11.5599L12.8814 12.4819C13.0779 12.5448 13.2903 12.4429 13.3558 12.2542C13.4213 12.0656 13.3151 11.8617 13.1186 11.7989L10.375 10.9209V9.74042H12C13.4142 9.74042 14.1213 9.74042 14.5607 9.31866C15 8.8969 15 8.21808 15 6.86045V6.38046C15 5.02283 15 4.34401 14.5607 3.92225C14.1213 3.50049 13.4142 3.50049 12 3.50049H8C6.58579 3.50049 5.87868 3.50049 5.43934 3.92225ZM7.59948 6.40078C8.04131 6.40078 8.39948 6.04261 8.39948 5.60078C8.39948 5.15895 8.04131 4.80078 7.59948 4.80078C7.15765 4.80078 6.79948 5.15895 6.79948 5.60078C6.79948 6.04261 7.15765 6.40078 7.59948 6.40078Z"
      fill="white"
    />
  </svg>
)
