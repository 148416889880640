export const GGBLogoWideNavDark = '/images/GGB-Office-Landscape-transparent-darkbg.png'
export const GGBLogoWideNavDark2x = '/images/GGB-Office-Landscape-transparent-darkbg@2x.png'
export const BackgroundDefault = '/images/background-default.jpg'
export const GGBAppImg = '/images/golfgamebook-app.png'
export const GGBAppImg2x = '/images/golfgamebook-app@2x.png'
export const GGBAppQR = '/images/golfgamebook-app-qr.png'
export const GGBAppQR2x = '/images/golfgamebook-app-qr@2x.png'
export const AppStore = '/images/app-store.png'
export const GooglePlay = '/images/google-play.png'
export const PoweredByGGBlight = '/images/powered_by_GGB_lightbg.png'
export const LeaderboardBg = '/images/leaderboard-bg-PelicanHill.jpg'
export const LeaderboardBgGreyscale = '/images/leaderboard-bg-PelicanHill-greyscale.jpg'
export const FiFlag = '/images/flags/fi.png'
export const EnFlag = '/images/flags/en.png'
export const SvFlag = '/images/flags/sv.png'
export const EsFlag = '/images/flags/es.png'
export const SignUpSuccessWeb = '/images/signup-success-web.jpg'
export const SignUpSuccessWeb2x = '/images/signup-success-web@2x.jpg'
export const AppAdDownloadBackgroundWeb = '/images/app-ad-download-bg-web.jpg'
export const AppAdDownloadBackgroundMob = '/images/app-ad-download-bg-mob.jpg'
