import { SimulatorIcon } from '@app/assets/images/icons/simulator'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  simulator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1em 0',
  },
  simulatorContainer: {
    width: '214px',
    height: '30px',
    borderRadius: 16,
    backgroundColor: '#004E8F',
    color: 'white',
    padding: '0.2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  simulatorText: {
    marginLeft: '0.5em',
  },
}))

export const SimulatorTag = ({ text }) => {
  const classes = useStyles()
  return (
    <div className={classes.simulator}>
      <div className={classes.simulatorContainer}>
        <SimulatorIcon height={18} width={33} />
        <span className={classes.simulatorText}>{text}</span>
      </div>
    </div>
  )
}
