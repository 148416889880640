import { TOURNAMENT_LOAD_CONTEST_LEADERBOARD } from './constants'

/*
 * TOURNAMENT LOAD CONTEST LEADERBOARD
 */

export interface LoadTournamentContestLeaderboardAction extends Action {
  type: 'TOURNAMENT_LOAD_CONTEST_LEADERBOARD'
  id: number
  onSuccess?: () => void
}
export const loadTournamentContestLeaderboard = (id: number, onSuccess?: () => void) => ({
  type: TOURNAMENT_LOAD_CONTEST_LEADERBOARD,
  id,
  onSuccess,
})

export interface LoadTournamentContestLeaderboardSuccessAction extends Action {
  type: 'TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS'
  data: TournamentContestLeaderboard
  loading: boolean
}

export interface LoadTournamentContestLeaderboardFailureAction extends Action {
  type: 'TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE'
  error: ErrorPayload
}
