import { SimulatorIcon } from '@app/assets/images/icons/simulator'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  simulatorContainer: {
    height: '2.6em',
    borderRadius: 9,
    backgroundColor: '#004E8F',
    color: 'white',
    padding: '0.2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    overflow: 'hidden',
    marginBottom: '0.5em',
  },
  simulatorText: {
    fontSize: '0.875em',
    marginLeft: '0.5em',
  },
}))

export const SimulatorBar = ({ text }) => {
  const classes = useStyles()
  return (
    <div className={classes.simulatorContainer}>
      <SimulatorIcon height={25} width={40} />
      <span className={classes.simulatorText}>{text}</span>
    </div>
  )
}
