import React from 'react'
import Dialog from '@mui/material/Dialog'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@mui/styles'
import TourButton from '../ui/TourButton'
import { Theme, Typography } from '@mui/material'
import { LockIcon, SuccessIcon, WarningIcon } from '@app/assets/images/icons'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  popupModal: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 347,
      borderRadius: 12,
      margin: 14,
      padding: '70px 20px 50px',
      textAlign: 'center',
    },

    '&.wide': {
      '& .MuiDialog-paper': {
        [theme.breakpoints.up('md')]: {
          maxWidth: 757,
          padding: '90px 150px 100px',
        },
      },
    },
  },
  buttonsWrapper: {
    padding: '0 25px',

    '& .MuiButtonBase-root': {
      marginBottom: 10,
    },
  },
  fullWidthButton: {
    width: '100%',
  },
  headingBlack: {
    color: theme.palette.mode === 'light' ? '#000' : '#fff',
  },
}))

interface Props {
  open: boolean
  type?: 'success' | 'warning' | 'lock' | 'neutralBlack' | 'neutralGreen'
  wide?: boolean
  title?: React.ReactNode
  text?: React.ReactNode
  okText?: React.ReactNode
  cancelText?: React.ReactNode
  cancelAction?: () => void
  okAction?: () => void
}

/**
 * A small popup modal with an optional icon, title, text, and one or two buttons.
 */

export default function Popup({
  open,
  type = 'neutralGreen',
  wide = false,
  title,
  text,
  okText,
  cancelText,
  okAction,
  cancelAction,
}: Props) {
  const classes = useStyles()

  const handleOk = () => {
    if (okAction) okAction()
  }

  const handleCancel = () => {
    if (cancelAction) cancelAction()
  }

  const okButtonColor = type === 'neutralBlack' ? 'black' : 'primary'
  const headingClass = ['warning', 'neutralBlack', 'neutralGreen'].includes(type) ? classes.headingBlack : ''

  return (
    <div>
      <Dialog className={classNames(classes.popupModal, { wide })} open={open} onClose={handleCancel}>
        {type === 'success' && <SuccessIcon style={{ width: 85, height: 85, margin: '0 auto 10px' }} />}
        {type === 'warning' && <WarningIcon style={{ width: 85, height: 85, margin: '0 auto 10px' }} />}
        {type === 'lock' && <LockIcon style={{ width: 85, height: 85, margin: '0 auto 10px' }} />}
        {title && (
          <Typography variant="h2" component="h2" sx={{ fontSize: 20 }} className={headingClass}>
            {title}
          </Typography>
        )}
        <p style={{ marginTop: 16, marginBottom: 30, fontSize: 16, lineHeight: 1.5 }}>{text}</p>
        <div className={classes.buttonsWrapper}>
          {okAction && (
            <TourButton buttonProps={{ onClick: handleOk }} color={okButtonColor} className={classes.fullWidthButton}>
              {okText ? okText : <FormattedMessage id="buttons.ok" />}
            </TourButton>
          )}
          {cancelAction && (
            <TourButton buttonProps={{ onClick: handleCancel }} color="secondary" className={classes.fullWidthButton}>
              {cancelText ? cancelText : <FormattedMessage id="buttons.cancel" />}
            </TourButton>
          )}
        </div>
      </Dialog>
    </div>
  )
}
