import React from 'react'
import { Theme, Toolbar } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import { LanguageSwitcher } from './LanguageSwitcher'

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: 'transparent',
      backgroundImage: 'none',
      boxShadow: 'none',
      position: 'relative',
      minHeight: '60vh',
      [theme.breakpoints.down('sm')]: {
        minHeight: 0,
      },
    },
    toolbar: {
      maxWidth: 1920,
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      minHeight: 100,
      boxSizing: 'border-box',
      alignItems: 'unset',
      padding: 40,
      [theme.breakpoints.down('sm')]: {
        padding: '10px 10px 65px 10px',
      },
    },
    leftCol: {
      alignSelf: 'center',
      display: 'flex',
    },
    rightCol: {
      alignSelf: 'flex-start',
    },
    colWrap: {
      flexDirection: 'row',
      display: 'flex',
    },
    logoImage: {
      display: 'flex',
      width: 100,
      height: 100,
      borderRadius: '50%',
      backgroundColor: theme.customPalette.background,
      [theme.breakpoints.down('sm')]: {
        width: 44,
        height: 44,
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  tournamentSite?: TournamentSite
  tour?: Tour
}

class Header extends React.Component<Props> {
  render(): React.ReactElement<any> | null {
    const { classes, tournamentSite, tour } = this.props

    if (!tournamentSite && !tour) {
      return null
    }

    const entity = tournamentSite ? tournamentSite : tour

    return (
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.leftCol}>
            {entity.logoImageUrl &&
              entity.logoImageUrl.length > 1 &&
              (entity.homepage ? (
                <a href={entity.homepage} target={'_blank'} rel="noreferrer">
                  <img className={classes.logoImage} src={entity.logoImageUrl} alt="" />
                </a>
              ) : (
                <img className={classes.logoImage} src={entity.logoImageUrl} alt="" />
              ))}
          </div>

          <div className={classes.rightCol}>
            <div className={classes.colWrap}>
              <LanguageSwitcher />
            </div>
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}

export default connect(
  (state: StoreState) => ({
    tournamentSite: state.tournamentReducer.tournamentSite,
    tour: state.tourAndRankingReducer.tour,
  }),
  null,
)(withStyles(styles)(Header))
