import { getTour } from '@app/store/tourAndRanking/actions'
import { getTournament } from '@app/store/tournament/actions'
import { loadTournamentLeaderboard } from '@app/store/tournamentLeaderboard/actions'
import { getTournamentTeams, loadTournamentPlayers } from '@app/store/tournamentPlayers/actions'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

type ResourceType = 'tournament' | 'leaderboard' | 'players' | 'teams' | 'tour'

type UseAppStateProps = {
  autoloadResources?: ResourceType[]
  tournamentId?: number
  divisionId?: number
  tourId?: number
}

type LoadSourcesProps = {
  resources: ResourceType[]
  tournamentId?: number
  divisionId?: number
  tourId?: number
}

export const useAppState = ({ autoloadResources, divisionId, ...props }: UseAppStateProps = {}) => {
  const dispatch = useDispatch()
  const { tournamentId: tournamentIdString, tourId: tourIdString } = useParams()
  const tournamentId = props.tournamentId || tournamentIdString ? parseInt(tournamentIdString) : undefined
  const tourId = props.tourId || tourIdString ? parseInt(tourIdString) : undefined

  const [resourcesState, setResourcesState] = useState<ResourceType[]>(autoloadResources)

  // Remove resource from array to avoid infinite loop
  const removeUsedResource = (res: ResourceType[], type: ResourceType) =>
    setResourcesState(res.splice(res.indexOf(type), 1))

  const loadResources = useCallback(
    ({ resources, ...rest }: LoadSourcesProps) => {
      if (resources.includes('tournament')) {
        dispatch(getTournament(rest.tournamentId || tournamentId))
        removeUsedResource(resources, 'tournament')
      }
      if (resources.includes('leaderboard')) {
        dispatch(loadTournamentLeaderboard(rest.tournamentId || tournamentId, rest.divisionId || divisionId))
        removeUsedResource(resources, 'leaderboard')
      }
      if (resources.includes('players')) {
        dispatch(loadTournamentPlayers(rest.tournamentId || tournamentId))
        removeUsedResource(resources, 'players')
      }
      if (resources.includes('teams')) {
        dispatch(getTournamentTeams(rest.tournamentId || tournamentId))
        removeUsedResource(resources, 'teams')
      }
      if (resources.includes('tour')) {
        dispatch(getTour(rest.tourId || tourId))
        removeUsedResource(resources, 'tour')
      }
      setResourcesState([])
    },
    [dispatch, divisionId, tourId, tournamentId],
  )

  useEffect(() => {
    if (resourcesState?.length && (tournamentId || tourId)) {
      loadResources({ resources: resourcesState })
    }
  }, [loadResources, tourId, tournamentId, resourcesState])

  return {
    tournamentId,
    tourId,
    divisionId,
    loadResources,
    leaderboardState: useSelector((state: StoreState) => state.tournamentLeaderboardReducer),
    tournamentPlayersState: useSelector((state: StoreState) => state.tournamentPlayersReducer),
    startlistsState: useSelector((state: StoreState) => state.tournamentStartlistsReducer),
    tournament: useSelector((state: StoreState) => state.tournamentReducer.tournamentSite?.tournament),
    tournamentSiteState: useSelector((state: StoreState) => state.tournamentReducer.tournamentSite),
    tournamentState: useSelector((state: StoreState) => state.tournamentReducer),
    tourAndRankingState: useSelector((state: StoreState) => state.tourAndRankingReducer),
  }
}
