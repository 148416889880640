import React from 'react'
import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import InfoIcon from '@mui/icons-material/Info'

interface OwnProps {
  numHiddenHoles: number
}

const useStyles = makeStyles((theme: Theme) => ({
  hiddenHolesNoticeContainer: {
    display: 'flex',
    background: theme.customPalette.tableAltLightBg,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.customPalette.primaryBright,
    borderRadius: 9,
    padding: '0.5em 3.5em 0.5em 0.75em',
    marginBottom: '0.5em',
    alignItems: 'center',
  },
  hiddenHolesInfoIcon: {
    fontSize: '1em',
    width: '1.5em',
    height: '1.5em',
    color: theme.customPalette.primaryBright,
  },
  hiddenHolesText: {
    fontSize: '0.875em',
    lineHeight: 1.375,
    fontWeight: 500,
    color: theme.customPalette.textColor,
  },
}))

export const LeaderboardHiddenHolesInfo: React.FC<OwnProps> = ({ numHiddenHoles }) => {
  const classes = useStyles()

  return (
    <div className={classes.hiddenHolesNoticeContainer}>
      <InfoIcon className={classes.hiddenHolesInfoIcon} />
      &nbsp;
      <span className={classes.hiddenHolesText}>
        <FormattedMessageWrapper
          id="leaderboard.hiddenHoles"
          values={{
            numHiddenHoles: numHiddenHoles,
          }}
        />
      </span>
    </div>
  )
}
