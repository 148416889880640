import React, { CSSProperties } from 'react'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'

const styles = () =>
  createStyles({
    root: {
      margin: '0 auto',
    },
  })

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode
  style?: CSSProperties
}

const ContentWrap = (props: Props) => {
  const { children, classes, style } = props

  return (
    <div style={style} className={classes.root}>
      {children}
    </div>
  )
}

export default withStyles(styles)(ContentWrap)

const innerWrapStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: `0 60px 30px`,

      [theme.breakpoints.down('md')]: {
        margin: `0 10px 10px`,
      },
      [theme.breakpoints.down('sm')]: {
        margin: `0 10px 10px`,
      },
    },
  })

export const InnerContentWrap = withStyles(innerWrapStyles)((props: Props) => {
  const { children, classes, style } = props

  return (
    <div style={style} className={classes.root}>
      {children}
    </div>
  )
})
