import React from 'react'
import { Helmet } from 'react-helmet-async'
import { SITE_TITLE, TITLE_DIVIDER } from '../../config'

const TWITTER_USERNAME = undefined
const DEFAULT_IMAGE: Image = {
  id: null,
  url: 'https://golfgamebook.com/wp-content/uploads/2017/10/score-entry.jpg',
}

const renderTitles = (title: string) => {
  // React Helmet doesn't support fragments, use array instead.
  return [
    <title key="1">{title}</title>,
    <meta key="2" name="og:title" property="og:title" content={title} />,
    <meta key="3" name="twitter:title" content={title} />,
  ]
}

const createTitle = (title?: string) => {
  if (!title) {
    return renderTitles(SITE_TITLE)
  }

  return renderTitles(`${title}${TITLE_DIVIDER}${SITE_TITLE}`)
}

const renderDescriptions = (description: string) => {
  return [
    <meta key="4" name="description" content={description} />,
    <meta key="5" property="og:description" content={description} />,
    <meta key="6" name="twitter:description" content={description} />,
  ]
}

const createDescription = (description?: string) => {
  if (description) {
    return renderDescriptions(description)
  }

  return null
}

const createImage = (image?: Image) => {
  if (!image?.url) {
    return null
  }

  return [
    <meta key="7" name="og:image" content={image.url} />,
    <meta key="8" name="twitter:image" content={image.url} />,
  ]
}

export type ConstructedHeadProps = {
  title?: string
  description?: string
  image?: Image
  noIndex?: boolean
  children?: React.ReactNode
}

export const ConstructedHead = ({
  title,
  children,
  description,
  image = DEFAULT_IMAGE,
  noIndex,
}: ConstructedHeadProps) => {
  return (
    <Helmet>
      {createTitle(title)}
      {createDescription(description)}
      {createImage(image)}

      <meta name="twitter:card" content="summary_large_image" />
      {Boolean(TWITTER_USERNAME) && <meta name="twitter:site" content={TWITTER_USERNAME} />}
      {Boolean(noIndex) && <meta name="robots" content="NOINDEX, NOFOLLOW" />}

      {children}
    </Helmet>
  )
}
