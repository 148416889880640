import {
  COUNTRIES_FETCH,
  COUNTRY_STATES_FETCH,
  COUNTRY_STATES_FETCH_SUCCESS,
  LOCAL_CLUBS_FETCH,
  UHS_CLUBS_FETCH,
} from './constants'

/*
 * Component Local clubs fetch
 */
export interface LocalFetchClubsAction extends Action {
  type: 'LOCAL_CLUBS_FETCH'
  searchTerm: string
  onComplete?: (result: ClubsAndCountriesAPICallResult) => void
  countryId?: number
}
export interface LocalFetchClubsSuccessAction extends Action {
  type: 'LOCAL_CLUBS_FETCH_SUCCESS'
  clubs: Club[]
}
export interface LocalFetchClubsFailureAction extends Action {
  type: 'LOCAL_CLUBS_FETCH_FAILURE'
  error: ErrorPayload
}

export const localFetchClubs = (
  searchTerm: string,
  onComplete: (result: ClubsAndCountriesAPICallResult) => void,
  countryId?: number,
) => ({
  type: LOCAL_CLUBS_FETCH,
  searchTerm,
  onComplete,
  countryId,
})

/*
 * Fetch UHS clubs
 */
export interface UHSFetchClubsAction extends Action {
  type: 'UHS_CLUBS_FETCH'
}
export interface UHSFetchClubsSuccessAction extends Action {
  type: 'UHS_CLUBS_FETCH_SUCCESS'
  clubs: Club[]
}
export interface UHSFetchClubsFailureAction extends Action {
  type: 'UHS_CLUBS_FETCH_FAILURE'
  error: ErrorPayload
}
export const fetchUHSClubs = () => ({
  type: UHS_CLUBS_FETCH,
})

/*
 * Global countries fetch
 */
export interface FetchCountriesAction extends Action {
  type: 'COUNTRIES_FETCH'
}

export interface FetchCountriesSuccessAction extends Action {
  type: 'COUNTRIES_FETCH_SUCCESS'
  countries: Country[]
}

export interface FetchCountriesFailureAction extends Action {
  type: 'COUNTRIES_FETCH_FAILURE'
  error: ErrorPayload
}

export const fetchCountries = () => {
  return {
    type: COUNTRIES_FETCH,
  }
}

/*
 * Global country states fetch
 */
export interface FetchCountryStatesAction extends Action {
  type: 'COUNTRY_STATES_FETCH'
  countryId: number
}

export interface FetchCountryStatesSuccessAction extends Action {
  type: 'COUNTRY_STATES_FETCH_SUCCESS'
  states: CountryState[]
}

export interface FetchCountryStatesFailureAction extends Action {
  type: 'COUNTRY_STATES_FETCH_FAILURE'
  error: ErrorPayload
}

export const fetchCountryStates = (countryId: number): FetchCountryStatesAction => {
  return {
    type: COUNTRY_STATES_FETCH,
    countryId,
  }
}

export const fetchCountryStatesSuccess = (states: CountryState[]): FetchCountryStatesSuccessAction => {
  return {
    type: COUNTRY_STATES_FETCH_SUCCESS,
    states,
  }
}
