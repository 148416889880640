import React, { useMemo, useState } from 'react'
import { formatDefaultTournamentSeo } from '@app/components/seo/utils'
import OverlayLoader from '@app/components/ui/OverlayLoader'
import { ConstructedHead } from '@app/components/seo/ConstructedHead'
import { Theme, Grid, Typography, TableContainer, Table, TableBody, TableRow, TableCell, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import ScreenMenu from '@app/components/screen/ScreenMenu'
import { LeaderboardBg, LeaderboardBgGreyscale, PoweredByGGBlight } from '../../assets/images'
import ContestsDataScreen from '@app/components/screen/ContestsDataScreen'
import { useAppState } from '@app/hooks'
import { LeaderboardHiddenHolesInfo } from '@app/components/tournament/ui/LeaderboardHiddenHolesInfo'
import GameWebLeaderboard from '@app/components/screen/GameWebLeaderboard'
import { useSelector } from 'react-redux'
import { ColorTheme } from '@app/utils/enums'
import { SimulatorBar } from '@app/components/ui/SimulatorBar'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: ['Public sans', 'sans-serif'].join(','),
    position: 'relative',
    zIndex: 0,
    margin: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundImage:
      theme.palette.mode === ColorTheme.LIGHT.toLowerCase()
        ? `url(${LeaderboardBg})`
        : `url(${LeaderboardBgGreyscale})`,
    height: '100vh',
    overflow: 'hidden',
    padding: '30px 0',
    display: 'grid',
    gridTemplateRows: 'min-content min-content minmax(auto, min-content) min-content',
    gridTemplateColumns: '1fr',
    fontSize: '16px',

    '@media only screen and (min-width: 1500px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width: 1900px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width: 2500px)': {
      fontSize: '22px',
    },
    '@media only screen and (min-width: 3800px)': {
      fontSize: '32px',
    },
  },
  backgroundTint: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#00341E',
    opacity: 0.9,
    zIndex: -1,
  },
  headerSection: {
    marginBottom: '0.5em',
  },
  messageSection: {},
  leaderboardSection: {
    overflow: 'auto',
    scrollbarWidth: 'none',
  },
  poweredBySection: {
    paddingTop: '3px',
  },
  gridContainer: {
    width: '90%',
    justifyContent: 'center',
    margin: '0 auto',

    '@media only screen and (min-width: 1500px)': {
      width: '80%',
    },
    '@media only screen and (min-width: 1900px)': {
      width: '65%',
    },
    '@media only screen and (min-width: 2500px)': {
      width: '60%',
    },
    '@media only screen and (min-width: 3800px)': {
      width: '60%',
    },
  },
  leaderboardHeaderContainer: {
    color: theme.palette.common.white,
    zIndex: 11,
  },
  leaderboardHeader: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: theme.customPalette.brandGradient,
    borderRadius: '3px',
    padding: '1em',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 2px 0px',
    '& h2': {
      color: theme.palette.primary.contrastText,
      fontSize: '2em',
      fontStyle: 'normal',
      lineHeight: '1',
    },
    '& > .MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 0,
    },
  },
  leaderboardHeaderDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      color: theme.palette.primary.contrastText,
      fontSize: '0.875em',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  leaderboardHeaderActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& p': {
      color: theme.palette.primary.contrastText,
      fontSize: '1.125em',
    },
  },
  messagesContainer: {
    paddingTop: '0 !important',
    paddingLeft: '0 !important',
  },
  leaderboardContainer: {
    paddingLeft: '0 !important',
  },
  contestsWrapper: {
    padding: '0.1875em 0 0 0.5em !important',
  },
  contestsContainer: {
    backgroundColor:
      theme.palette.mode === ColorTheme.LIGHT.toLowerCase() ? 'rgba(255, 255, 255, 0.18)' : 'rgba(0, 0, 0, 0.18)',
    height: '100%',
    position: 'relative',
    borderRadius: '3px',
  },
  menu: {
    color: theme.palette.primary.contrastText,
  },
  logoContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 11,
    padding: '0 0.5em 0 0 !important',
    '@media only screen and (max-width: 959px)': {
      padding: '0 0 0.5em 0 !important',
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.customPalette.background,
    height: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 2px 0px',
    borderRadius: '3px',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '5.625em',
  },
  ggbLogo: {
    display: 'block',
    marginLeft: 'auto',
    width: '12.5em',
    marginTop: '0.625em',
  },
  menuContainer: {
    display: 'flex',
  },
  columnDivider: {
    padding: '0 1.25em',
  },
  headerTable: {
    marginTop: '0.625em',
    width: 'auto',

    '& .MuiTableCell-root': {
      fontFamily: ['Public sans', 'sans-serif'].join(','),
    },
  },
  headerTableRow: {
    '& td': {
      color: theme.palette.common.white,
      fontSize: '1em',
      fontWeight: 400,
      border: 0,
      padding: 0,
    },
  },
  tableCellDivider: {
    padding: '0 0.75em !important',
  },
  gameModeText: {
    display: 'block',
    fontWeight: 700,
    fontSize: '1.675em',
    letterSpacing: '-0.562px',
    lineHeight: '1',
    textAlign: 'right',
  },
  divisionText: {
    display: 'block',
    fontSize: '1em',
    fontWeight: 500,
    textAlign: 'right',
  },
  poweredByContainer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'end',
  },
  poweredBy: {
    width: '18.25em',
    position: 'relative',

    '& svg': {
      width: '100%',
      color: theme.customPalette.tableAltLightBg,
    },

    '& img': {
      position: 'absolute',
      top: '0.75em',
      right: '2.0625em',
      width: '10.8125em',
      filter: theme.palette.mode === ColorTheme.LIGHT.toLowerCase() ? undefined : 'contrast(0) brightness(100)',
    },
  },
}))

export const TournamentScreen = () => {
  const theme = useTheme()
  const intl = useIntl()
  const classes = useStyles()
  const [gameType, setGameType] = useState<'primary' | 'side' | 'contests'>('primary')
  const [isAutoRolling, setAutoRolling] = useState(false)
  const [rollingSpeed, setRollingSpeed] = useState<number>(0)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const {
    tournamentSiteState,
    leaderboardState,
    tournament,
    tournamentId,
    tournamentState: { isLoading },
  } = useAppState({ autoloadResources: ['tournament', 'leaderboard'] })
  const { activeDivision } = useSelector((state: StoreState) => state.tournamentReducer)

  const logoUrl = tournamentSiteState?.images?.logoImage?.url
  const contestsEnabled = tournamentSiteState?.tournament.rounds.some((r) => r.contestsEnabled)
  const firstRound = tournament?.rounds[0]

  // Taken from last round only
  const numHolesHidden = tournament?.rounds.at(-1)?.hideResultsFromLastHoles

  const gameModeNames = {
    primary: firstRound?.primaryGame && intl.formatMessage({ id: `gameFormats.type${firstRound.primaryGame.id}` }),
    side: firstRound?.sideGame && intl.formatMessage({ id: `gameFormats.type${firstRound.sideGame.id}` }),
  }

  const gameModeName = useMemo(() => {
    if (!firstRound) {
      return ''
    }
    switch (gameType) {
      case 'contests':
        return intl.formatMessage({ id: 'gameFormatTypes.contests' })
      case 'side':
        return gameModeNames.side
      default:
        return gameModeNames.primary
    }
  }, [firstRound, gameType])

  const divisionName = useMemo(() => {
    if (!tournamentSiteState?.divisions.length) {
      return ''
    } else if (0 === activeDivision) {
      return intl.formatMessage({ id: 'leaderboard.allPlayers' })
    } else {
      return tournamentSiteState.divisions.find((d) => d.id === activeDivision)?.name
    }
  }, [activeDivision, tournamentSiteState])

  const leaderboardGameMode = useMemo(() => {
    switch (gameType) {
      case 'side':
        return 'secondary'
      case 'contests':
        return 'primary'
      default:
        return gameType
    }
  }, [gameType])

  const sideGameEnabled = firstRound?.sideGameEnabled

  const renderLeaderboardHeaderDetailRow = (round: TournamentRound, index: number) => {
    if (!leaderboardState.data) {
      return
    }
    const { rounds } = leaderboardState.data
    const { club, course, startTime } = round

    return (
      <TableRow className={classes.headerTableRow} key={round.id}>
        {rounds && rounds.length > 1 && (
          <TableCell style={{ paddingRight: '0.625em' }}>
            {intl.formatMessage({ id: 'strings.r' })}
            {index + 1}:
          </TableCell>
        )}
        {rounds && rounds.length === 1 && <TableCell>{club.name}</TableCell>}
        {rounds && rounds.length === 1 && <TableCell className={classes.tableCellDivider}>|</TableCell>}
        <TableCell>{course.courseName}</TableCell>
        <TableCell className={classes.tableCellDivider}>|</TableCell>
        <TableCell>
          <FormattedMessage id="leaderboard.par" /> {course.par}
        </TableCell>
        <TableCell className={classes.tableCellDivider}>|</TableCell>
        <TableCell>{moment(startTime).format('D.M.YYYY')}</TableCell>
      </TableRow>
    )
  }

  const renderLeaderboardHeaderDetails = (rounds: TournamentRound[]) => {
    return (
      <TableContainer style={{ overflow: 'hidden' }}>
        <Table className={classes.headerTable} size="small">
          <TableBody>{rounds.map(renderLeaderboardHeaderDetailRow)}</TableBody>
        </Table>
      </TableContainer>
    )
  }

  const renderContestsData = () => {
    return <ContestsDataScreen tournamentId={tournamentId} />
  }

  const onPageClick = () => {
    if (isAutoRolling && !isMenuOpen) {
      setAutoRolling(false)
    }
  }

  if (!tournament || isLoading) {
    return <OverlayLoader />
  }

  return (
    <>
      <ConstructedHead {...formatDefaultTournamentSeo({ pageName: 'Tournament Screen' })} noIndex />
      <main className={classes.root} onClick={onPageClick}>
        {theme.palette.mode === ColorTheme.DARK.toLowerCase() && <div className={classes.backgroundTint}></div>}
        <section className={classes.headerSection}>
          <Grid container spacing={2} className={classes.gridContainer}>
            {logoUrl && (
              <Grid item xs={12} md={2} className={classes.logoContainer}>
                <div className={classes.logoWrapper}>
                  <img src={logoUrl} className={classes.logo} alt="" />
                </div>
              </Grid>
            )}
            <Grid item xs={12} md={logoUrl ? 10 : 12} className={classes.leaderboardHeaderContainer}>
              <Grid container spacing={2} className={classes.leaderboardHeader}>
                <Grid item xs={12} sm={7}>
                  <Typography variant="h2PublicSans">{tournament.name}</Typography>
                  {renderLeaderboardHeaderDetails(tournament.rounds)}
                </Grid>

                <Grid item xs={12} sm={5} className={classes.leaderboardHeaderActions}>
                  <div className={classes.menuContainer}>
                    <div>
                      <span className={classes.gameModeText}>{gameModeName}</span>
                      <span className={classes.divisionText}>{divisionName}</span>
                    </div>
                    <ScreenMenu
                      gameModeNames={gameModeNames}
                      selectedGameType={gameType}
                      setSelectedGameType={(selected) => setGameType(selected as any)}
                      rolling={isAutoRolling}
                      setRolling={setAutoRolling}
                      rollingSpeed={rollingSpeed}
                      setRollingSpeed={setRollingSpeed}
                      sideGameEnabled={sideGameEnabled}
                      setIsMenuOpen={setIsMenuOpen}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>

        <section className={classes.messageSection}>
          <Grid container spacing={0} className={classes.gridContainer}>
            {numHolesHidden > 0 && (
              <Grid item xs={tournament.settings.isSimulator ? 4 : 12} className={classes.messagesContainer}>
                <LeaderboardHiddenHolesInfo numHiddenHoles={numHolesHidden} />
              </Grid>
            )}
            {tournament.settings.isSimulator && (
              <Grid item xs={numHolesHidden > 0 ? 8 : 12} className={classes.messagesContainer}>
                <SimulatorBar text={intl.formatMessage({ id: 'strings.simulatorTournament' })} />
              </Grid>
            )}
          </Grid>
        </section>

        <section className={classes.leaderboardSection} id="scrollable-container">
          <Grid container className={classes.gridContainer} style={{ marginTop: '-0.1875em' }}>
            <Grid item xs={12} md={contestsEnabled ? 9 : 12} className={classes.leaderboardContainer}>
              <GameWebLeaderboard
                autoScroll={isAutoRolling}
                autoScrollSpeed={rollingSpeed}
                forceGameType={leaderboardGameMode}
              />
            </Grid>

            {contestsEnabled && (
              <Grid item xs={12} md={3} className={classes.contestsWrapper}>
                <div className={classes.contestsContainer}>
                  <div style={{ position: 'sticky', top: 0 }}>{renderContestsData()}</div>
                </div>
              </Grid>
            )}
          </Grid>
        </section>

        <section className={classes.poweredBySection}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} md={contestsEnabled ? 9 : 12}>
              <div className={classes.poweredByContainer}>
                <div className={classes.poweredBy}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="11 10 384.9 79" fill="none">
                    <g>
                      <path
                        // eslint-disable-next-line max-len
                        d="M396 13C396 11.3431 394.657 10 393 10H14.0994C11.3154 10 10.0329 13.4626 12.1451 15.2761L96.004 87.2761C96.548 87.7432 97.2412 88 97.9583 88H393C394.657 88 396 86.6569 396 85V13Z"
                        fill="currentColor"
                        style={{ filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.24))' }}
                      />
                    </g>
                  </svg>
                  <img src={PoweredByGGBlight} alt="Powered by Golf Gamebook" />
                </div>
              </div>
            </Grid>
            {contestsEnabled && <Grid item xs={12} md={3}></Grid>}
          </Grid>
        </section>
      </main>
    </>
  )
}
