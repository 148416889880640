import { PaymentTypes, PlayerHcpSource } from '@app/utils/enums'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { QuestionAnswer, TeamMember } from 'types/signUp'

/**
 * Basically a state machine for the signup process modals
 */

export type SelectedProductIds = number[]
export enum Step {
  'yourInformation',
  'team',
  'payment',
}
export enum PaymentStep {
  'paymentOptions',
  'productsList',
}

export const teamMemberSkeleton: TeamMember = {
  firstName: '',
  lastName: '',
  hcp: 0,
  hcpSource: PlayerHcpSource.UNCHANGED,
  gender: '',
  customQuestions: [],
}

const initialState = {
  signupProcessOpen: false,
  loginModalOpen: false,
  registerModalOpen: false,
  registrationSuccessModalOpen: false,
  signupModalOpen: false,
  signupCancelledModalOpen: false,
  signupSuccessModalOpen: false,
  tournamentFullModalOpen: false,
  tournamentFullModalShown: false,
  termsAndConditionsAccepted: false,
  paymentTermsAndConditionsAccepted: false,
  signupSuccess: false,
  onReserveList: false,
  step: Step.yourInformation,
  paymentStep: PaymentStep.paymentOptions,
  paymentOption: PaymentTypes.NOW,
  selectedProductIds: [] as SelectedProductIds,
  boughtProductIds: [] as SelectedProductIds,
  showAlert: false,
  alert: {
    title: '',
    message: '',
  },
  mandatoryDivisionError: false,
  partialTeamError: false,
  partialTeamWarning: false,
  partialTeamWarningHasBeenShown: false,
  existingUserAddedNotification: false,
  loading: false,
  user: {
    customQuestions: [] as QuestionAnswer[],
    divisionId: null as number | null,
    hcp: null as number | null,
    hcpSource: PlayerHcpSource.UNCHANGED,
    teebox: null as number | null,
  },
  team: {
    id: null as number | null,
    players: [] as TeamMember[],
    name: '',
    divisionId: null as number | null,
  },
  invalidTeamMemberIds: [] as number[],
}

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    openSignupProcess: (state) => {
      state.signupProcessOpen = true
      state.loginModalOpen = true
    },
    closeSignupProcess: () => {
      return initialState
    },
    registering: (state) => {
      state.registerModalOpen = true
    },
    registerModalDismissed: (state) => {
      state.registerModalOpen = false
    },
    registered: (state) => {
      state.registrationSuccessModalOpen = true
      state.registerModalOpen = false
      state.signupModalOpen = true
    },
    registrationSuccessModalDismissed: (state) => {
      state.registrationSuccessModalOpen = false
    },
    loggedIn: (state) => {
      state.loginModalOpen = false
      state.signupModalOpen = true
    },
    tournamentFullModalOpened: (state) => {
      state.tournamentFullModalShown = true
      state.tournamentFullModalOpen = true
    },
    tournamentFullModalDismissed: (state) => {
      state.tournamentFullModalOpen = false
    },
    signupSuccessful: (state, action: PayloadAction<{ onReserveList: boolean; showSuccessModal: boolean }>) => {
      state.signupSuccess = true
      state.onReserveList = action.payload.onReserveList
      state.signupSuccessModalOpen = action.payload.showSuccessModal
    },
    signupCancelled: (state) => {
      state.signupCancelledModalOpen = true
    },
    signupCancelledModalDismissed: () => {
      return initialState
    },
    termsAndConditionsAcceptance: (state, action: PayloadAction<{ accepted: boolean }>) => {
      state.termsAndConditionsAccepted = action.payload.accepted
    },
    stepChanged: (state, action: PayloadAction<{ step: Step }>) => {
      state.step = action.payload.step
    },
    paymentStepChanged: (state, action: PayloadAction<{ step: PaymentStep }>) => {
      state.paymentStep = action.payload.step
    },
    paymentOptionSelected: (state, action: PayloadAction<{ option: PaymentTypes }>) => {
      state.paymentOption = action.payload.option
    },
    paymentTermsAndConditionsAcceptance: (state, action: PayloadAction<{ accepted: boolean }>) => {
      state.paymentTermsAndConditionsAccepted = action.payload.accepted
    },
    productSelectionChanged: (state, action: PayloadAction<{ productIds: SelectedProductIds }>) => {
      state.selectedProductIds = action.payload.productIds
    },
    alertMessageShown: (state, action: PayloadAction<{ title: string; message: string }>) => {
      state.showAlert = true
      state.alert = action.payload
    },
    alertMessageDismissed: (state) => {
      state.showAlert = false
      state.alert = initialState.alert
    },
    mandatoryDivisionErrorUpdated: (state, action: PayloadAction<{ error: boolean }>) => {
      state.mandatoryDivisionError = action.payload.error
    },
    loadingStateChanged: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.loading = action.payload.loading
    },
    userFieldUpdated: (state, action: PayloadAction<{ fieldName: string; value: string | number }>) => {
      state.user[action.payload.fieldName] = action.payload.value
    },
    userQuestionAnswerUpdated: (state, action: PayloadAction<{ id: number; answer: string }>) => {
      const index = state.user.customQuestions.findIndex((q) => q.id === action.payload.id)
      if (index !== -1) {
        state.user.customQuestions[index].answer = action.payload.answer
      } else {
        state.user.customQuestions.push(action.payload)
      }
    },
    teamDivisionChanged: (state, action: PayloadAction<{ divisionId: number }>) => {
      state.team.divisionId = action.payload.divisionId
    },
    teamMemberAdded: (state, action: PayloadAction<{ teamMember: TeamMember }>) => {
      const newTeamMember = action.payload.teamMember?.teamPlayerOrder
        ? action.payload.teamMember
        : { ...action.payload.teamMember, teamPlayerOrder: state.team.players.length + 1 }
      state.team.players = [...state.team.players, { ...teamMemberSkeleton, ...newTeamMember }]
    },
    teamMemberEdited: (state, action: PayloadAction<{ teamMember: TeamMember }>) => {
      const teamMemberIndex = state.team.players.findIndex(
        (member) => member.userId === action.payload.teamMember.userId,
      )
      state.team.players[teamMemberIndex] = action.payload.teamMember
    },
    teamMemberQuestionAnswerUpdated: (state, action: PayloadAction<{ userId: Number; id: number; answer: string }>) => {
      const teamMemberIndex = state.team.players.findIndex((member) => member.userId === action.payload.userId)
      if (teamMemberIndex !== -1) {
        const questionIndex = state.team.players[teamMemberIndex].customQuestions.findIndex(
          (q) => q.id === action.payload.id,
        )
        if (questionIndex !== -1) {
          state.team.players[teamMemberIndex].customQuestions[questionIndex].answer = action.payload.answer
        } else {
          state.team.players[teamMemberIndex].customQuestions.push(action.payload)
        }
      }
    },
    teamMemberRemoved: (state, action: PayloadAction<{ userId: number }>) => {
      state.team.players = state.team.players.filter((member) => member.userId !== action.payload.userId)
    },
    registrationTeamFetched: (state, action: PayloadAction<{ team }>) => {
      state.team = action.payload.team
    },
    teamNameChanged: (state, action: PayloadAction<{ teamName: string }>) => {
      state.team.name = action.payload.teamName
    },
    partialTeamErrorUpdated: (state, action: PayloadAction<{ error: boolean }>) => {
      state.partialTeamError = action.payload.error
    },
    partialTeamWarningUpdated: (state, action: PayloadAction<{ warning: boolean }>) => {
      state.partialTeamWarning = action.payload.warning
      if (action.payload.warning) {
        state.partialTeamWarningHasBeenShown = true
      }
    },
    existingUserAddedNotificationUpdated: (state, action: PayloadAction<{ notification: boolean }>) => {
      state.existingUserAddedNotification = action.payload.notification
    },
    invalidTeamMembersUpdated: (state, action: PayloadAction<{ invalidTeamMemberIds: number[] }>) => {
      state.invalidTeamMemberIds = action.payload.invalidTeamMemberIds
    },
    boughtProductsFetched: (state, action: PayloadAction<{ products: SelectedProductIds }>) => {
      if (action.payload.products.length) {
        // User has already bought products, disable "pay later" option
        state.paymentOption = PaymentTypes.NOW
        state.paymentStep = PaymentStep.productsList
        state.boughtProductIds = action.payload.products
      }
    },
  },
})

export const {
  openSignupProcess,
  closeSignupProcess,
  registering,
  registerModalDismissed,
  registered,
  registrationSuccessModalDismissed,
  loggedIn,
  tournamentFullModalOpened,
  tournamentFullModalDismissed,
  signupSuccessful,
  signupCancelled,
  signupCancelledModalDismissed,
  termsAndConditionsAcceptance,
  stepChanged,
  paymentStepChanged,
  paymentOptionSelected,
  paymentTermsAndConditionsAcceptance,
  productSelectionChanged,
  alertMessageShown,
  alertMessageDismissed,
  mandatoryDivisionErrorUpdated,
  loadingStateChanged,
  userFieldUpdated,
  userQuestionAnswerUpdated,
  teamDivisionChanged,
  teamMemberAdded,
  teamMemberEdited,
  teamMemberQuestionAnswerUpdated,
  teamMemberRemoved,
  registrationTeamFetched,
  teamNameChanged,
  partialTeamErrorUpdated,
  partialTeamWarningUpdated,
  existingUserAddedNotificationUpdated,
  invalidTeamMembersUpdated,
  boughtProductsFetched,
} = signupSlice.actions

export default signupSlice.reducer
