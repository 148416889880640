import { TOURNAMENT_LOAD_PLAYERS, TOURNAMENT_TEAMS } from './constants'

/*
 * TOURNAMENT LOAD PLAYERS
 */
export interface LoadTournamentPlayersAction extends Action {
  type: 'TOURNAMENT_LOAD_PLAYERS'
  id: number
}

export interface LoadTournamentPlayersSuccessAction extends Action {
  type: 'TOURNAMENT_LOAD_PLAYERS_SUCCESS'
  players: Player[]
  loading: boolean
}
export interface LoadTournamentPlayersFailureAction extends Action {
  type: 'TOURNAMENT_LOAD_PLAYERS_FAILURE'
  error: ErrorPayload
}

export interface SetReserveListAction extends Action {
  type: 'TOURNAMENT_SET_RESERVE_LIST'
  reserveList: Player[]
}

export interface SetPlayerPoolAction extends Action {
  type: 'TOURNAMENT_SET_PLAYER_POOL'
  players: Player[]
}

export const loadTournamentPlayers = (id: number) => ({
  type: TOURNAMENT_LOAD_PLAYERS,
  id,
})

export interface GetTournamentTeamsAction extends Action {
  type: 'TOURNAMENT_TEAMS'
  tournamentId: number
}

export interface GetTournamentTeamsSuccessAction extends Action {
  type: 'TOURNAMENT_TEAMS_SUCCESS'
  teams: any[]
}

export interface GetTournamentTeamsFailureAction extends Action {
  type: 'TOURNAMENT_TEAMS_FAILURE'
  error?: string
}
export const getTournamentTeams = (tournamentId: number): GetTournamentTeamsAction => ({
  type: TOURNAMENT_TEAMS,
  tournamentId,
})
